import React, { Component } from 'react';
import { Collapse, Row, Col, Container } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import BannerTop from './../../components/BannerTop';
import Api from '../../service/Api';
/* Import Component */
import FormularioDenuncia from './../../components/Forms/CanalDenuncia.jsx';
/* Import Imagenes */
import Vineta from '../../assets/images/vineta_up.png';

class CanalDenuncia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: {},
            collapse: 0,
            delito: {}
        };
        this.toggle = this.toggle.bind(this);
    }
    componentWillMount() {
        this.getContent();
        this.getDelitos();
    }
    async getContent() {
        const data = {}
        data.slug = this.state.slug;
        const response = await Api.getPageCanalDenuncia(data);
        this.setState({
            items: response[0]
        })
    }
    async getDelitos() {
        const response = await Api.getPageDelitos();
        this.setState({
            delito: response
        })
    }
    toggle(e, tab) {
        e.preventDefault();
        if (this.state.collapse !== tab) {
            this.setState({
                collapse: tab
            });
        } else {
            this.setState({
                collapse: 0
            });
        }
    }
    componentDidMount() {
        document.title = 'Sendero | Canal de Denuncia'
        window.scrollTo(0, 0);
    }

    render() {
        const { items, delito } = this.state;
        return (
            <div className="canal-denuncia-page">
                <Container fluid>
                    <BannerTop title1={items.nombre} image={items.image} />
                    {ReactHtmlParser(items.text_top)}
                    <br />
                    <Row className="sinPadding fondo-gris">
                        <Col className="fondo-gris">
                            <Container>
                                <Row>
                                    <Col xs={12} md={6}>
                                        {ReactHtmlParser(items.title_collapse)}
                                        <br />
                                        <Row className="canal-denuncias">
                                            {
                                                delito.length > 0 ? (
                                                    delito.map((item, index) => (
                                                        <Col sm="12" className="border-top" key={index}>
                                                            <div onClick={(e) => { this.toggle(e, index + 1) }} className={(parseInt(this.state.collapse) === (index + 1) ? 'open-list' : '') + ' text-list my-2'}>
                                                                {item.nombre}
                                                                <img src={Vineta} className="float-right icon-list" alt="" />
                                                            </div>
                                                            <Collapse isOpen={parseInt(this.state.collapse) === (index + 1) ? true : false}>
                                                                {ReactHtmlParser(item.content)}
                                                            </Collapse>
                                                        </Col>
                                                    ))
                                                ) : null
                                            }
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        {ReactHtmlParser(items.title_form)}
                                        <br />
                                        <FormularioDenuncia etico={false} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    {ReactHtmlParser(items.text_footer)}
                </Container>
            </div>
        );
    }
}

export default CanalDenuncia;
