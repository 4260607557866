import React from "react";
import "./../../../assets/scss/main.scss";
import Footer from "../../../components/Footer/Footer";
import MegaMenu from "../../../../components/Header";

const Layout = ({ bg, classes, children }) => {
  return (
    <>
      <div id="outer-container">
        <MegaMenu />
        <main className={bg}>
          <div className={`${classes} box-white`}>
            <div className="container">
              <div className="row">{children}</div>
            </div>
          </div>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
};

export default Layout;
