import React, { Component } from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';
class FooterRoutes extends Component {
    render() {
        return (
            <>
                <div className="d-block d-md-none">
                    <Mobile />
                </div>
                <div className="d-none d-md-block">
                    <Desktop />
                </div>
            </>
        );
    }
}

export default FooterRoutes;