import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
/* Import Component */
import FormularioDenuncia from './../../components/Forms/CanalDenuncia.jsx';
/* Import Imagenes */

class CanalDenunciaEtico extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            items: {},
            collapse: 0,
            delito: {}
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        document.title = 'Sendero | Canal de Denuncia Ético'
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="canal-denuncia-page">
                <Container fluid>
                        <div className="container">
                            <br />
                            <div className="row">
                                <div className="text-center col">
                                    <h2 className="text-bold">Bienvenido Al Canal De Denuncias Ético</h2>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col">
                                    <p>Bienvenidos al Canal de Denuncias de Sendero, una plataforma confidencial, segura e imparcial que te permitirá informar o denunciar cualquier hecho que sea considerado delito o contrario a la normativa interna de nuestra Compañía.</p>
                                    <br />
                                    <p>Todos los reclamos serán tratados según lo establecido en el procedimiento de denuncias e investigación, donde se estudiarán los casos y se evaluarán los conducto que éstos seguirá, para luego dar respuesta al denunciante.</p>
                                </div>
                                <div className="col">
                                    <p>Por esta razón, te solicitamos que utilices este canal con responsabilidad, señalando con precisión los hechos, el nombre de las personas involucradas y adjuntando archivos o imágenes que pudiesen ser de utilidad.</p>
                                    <br />
                                    <p>Agradecemos tu compromiso con los principios y valores establecidos por Sendero.</p>
                                </div>
                            </div>
                            <br />
                        </div>
                    <Row className="sinPadding fondo-gris">
                        <Col className="fondo-gris">
                            <Container>
                                <Row >
                                    <div className="col-md-6 offset-md-3">
                                    Agradecemos su compromiso con los principios y valores establecidos por Sendero. Para hacer su denuncia sólo debe llenar el siguiente formulario.
                                        <br />
                                        <br />
                                        <FormularioDenuncia etico={true}/>
                                    </div>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CanalDenunciaEtico;
