import React from "react";
import { getUser } from "../../services/Storage.Utils";
import Title from "./../Title/BoxText";
import Avatar from "./Avatar";
import "./NameUser.scss";

const NameUser = ({ st_medida_class }) => {
  return (
    <div className={`${st_medida_class} box-name-user`}>
      <div className="row">
        <div className="col-3 col-md-1 text-center">
          <Avatar />
        </div>
        <div className="col-9 order-first order-md-last col-md-11 text-left">
          <Title
            title1={"¡Hola"}
            title2={getUser().nombre}
            subtitle={"Bienvenido a Mi Sendero"}
          />
        </div>
      </div>
    </div>
  );
};

export default NameUser;
