import React from 'react'

const Spinner = () => {
    return (
      <span
        className="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      ></span>
    );
  };

export default Spinner