import React, { Component } from "react";

class AppSelect extends Component {
  render() {
    const { data, onChange, name, validateSelect } = this.props;
    return (
      <>
        <select
          name={name}
          id={name}
          className="form-control"
          onChange={onChange}
        >
          {name === "TipoProductoText" && (<option value="0">Seleccionar Tipo Producto</option>)}
          {name === "RegionText" && (<option value="0">Seleccionar Región</option>)}
          {name === "TipoProductoText"
            ? data.map((item, index) => (
                <>
                  {(validateSelect === "Maipú" || validateSelect === "Villa Alemana" || validateSelect === "Quilpué" || validateSelect === "Valparaíso" ) && item.type === 2 && (
                    <option value={index === 0 ? 0 : item.name} key={index}>
                      {item.name}
                    </option>
                  )}
                  {validateSelect === "San Antonio" ||
                  //validateSelect === "Villa Alemana" ||
                  //validateSelect === "Quilpué" ||
                  //validateSelect === "Valparaíso" ||
                  validateSelect === "San Bernardo"
                    ? item.type === 3 && (
                        <option value={index === 0 ? 0 : item.name} key={index}>
                          {item.name}
                        </option>
                      )
                    : item.type === 1 &&
                      (validateSelect !== "Maipú" && validateSelect !== "Villa Alemana" && validateSelect !== "Quilpué" && validateSelect !== "Valparaíso") && (
                        <option value={index === 0 ? 0 : item.name} key={index}>
                          {item.name}
                        </option>
                      )}
                </>
              ))
            : data.map((item, index) => (
                <>
                  {
                    <option value={item.name} key={index}>
                      {item.name}
                    </option>
                  }
                </>
              ))}
        </select>
      </>
    );
  }
}

export default AppSelect;
