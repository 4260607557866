import React from "react";
import { getContrato } from "../../services/Storage.Utils";
import TitleSection from "../TitleSection/TitleSection";
import ItemNotification from "./ItemNotification";

const SERVICES = [];
SERVICES.pie = true;
SERVICES.mpu = true;
const BoxNotification = () => {
  const Agreement = getContrato();
  return (
    <>
      <TitleSection
        title={"Notificación"}
        subtitle={""}
        all_medida_class={"d-block d-md-none"}
        st_medida_class={""}
      />
      {Agreement &&
        Agreement !== null &&
        Object.keys(Agreement).map((i, k) =>
          [Agreement[i]].map((item, key) => (
            <>
              {item.aviso && k === 0 && (
                <>
                  <ItemNotification
                    permite={item.aviso}
                    type="quota"
                    key={key}
                  />
                </>
              )}
              {item.alerta_pie && k === 0 && (
                <ItemNotification
                  permite={SERVICES["pie"]}
                  service={"pie"}
                  type="service"
                  key={i}
                />
              )}
              {item.alerta_mpu && k === 0 && (
                <ItemNotification
                  permite={SERVICES["mpu"]}
                  service={"mpu"}
                  type="service"
                  key={i}
                />
              )}
            </>
          ))
        )}
    </>
  );
};

export default BoxNotification;
