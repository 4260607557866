export default {
    menu: [
        {
            'name': '',
            'ruta': '',
            'classmenu': ' espacio ',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': '',
            'ruta': '',
            'classmenu': ' espacio ',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': '',
            'ruta': '',
            'classmenu': ' espacio ',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': '',
            'ruta': '',
            'classmenu': ' espacio ',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': '',
            'ruta': '',
            'classmenu': ' espacio ',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Noticias',
            'ruta': '/posts/noticias',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Más Parques',
            'ruta': '/mas-parque-para-todos',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Funerales del día',
            'ruta': '/funerales-del-dia',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Ubicación de sepulturas',
            'ruta': '/ubicacion-de-sepulturas',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
        {
            'name': 'Contacto',
            'ruta': '/contacto',
            'classmenu': '',
            'target': '_self',
            'estado': true,
            'type': 2,
        },
    ]

}