import React from "react";
import "./Button.scss";

const Button = ({ loader, handleLogin, name, classes, type }) => {
  const Spinner = () => {
    return (
      <span
        className="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      ></span>
    );
  };
  return (
    <button
      type={type === undefined ? "button" : type}
      className={classes}
      onClick={handleLogin}
      disabled={loader}
    >
      {loader ? <Spinner /> : name}
    </button>
  );
};

export default Button;
